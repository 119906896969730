import * as React from 'react'

import type { SVGProps } from 'react'

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="683.8 197.5 33 28"
    fill="currentColor"
    {...props}
  >
    <path d="m717.1 223.8-13.3-10c.9-1.6 1.5-3.4 1.5-5.3v-.2c0-2.9-1.2-5.6-3.1-7.6-1.9-2-4.6-3.2-7.5-3.2h-.2c-2.9 0-5.6 1.2-7.6 3.1-2 1.9-3.2 4.6-3.2 7.5v.2c0 2.9 1.2 5.6 3.1 7.6 1.9 2 4.6 3.2 7.5 3.2h.2c2.9 0 5.6-1.2 7.6-3.1.2-.2.3-.3.4-.5l13.3 10 1.3-1.7zm-22.5-6.9h-.1c-2.4 0-4.5-1-6-2.6s-2.5-3.7-2.5-6v-.1c0-2.4 1-4.5 2.6-6s3.7-2.5 6-2.5h.1c2.4 0 4.5 1 6 2.6s2.5 3.7 2.5 6v.1c0 1.8-.6 3.6-1.6 4.9l-.2.2c-.2.3-.5.6-.7.8-1.7 1.7-3.8 2.6-6.1 2.6z" />
  </svg>
)
export default SvgSearch
