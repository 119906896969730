import * as React from 'react'

import type { SVGProps } from 'react'

const SvgActionPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 28 28"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M10 0h7v28h-7z" />
    <path d="M0 11h28v6H0z" />
  </svg>
)
export default SvgActionPlus
