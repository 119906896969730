import * as React from 'react'

import type { SVGProps } from 'react'

const SvgUspIconsHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.74 28"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M23.45.06a9.46 9.46 0 0 0-7.58 2.68A9.27 9.27 0 0 0 8.3.06 9.41 9.41 0 0 0 .03 8.53a9.25 9.25 0 0 0 3.48 8l10.57 10.73a2.39 2.39 0 0 0 1.74.74 2.43 2.43 0 0 0 1.68-.68l10.69-10.69a9.39 9.39 0 0 0 3.52-8A9.45 9.45 0 0 0 23.45.06Zm2.42 13.68-10 10-9.84-9.84-.16-.16a5.6 5.6 0 0 1 2.85-10c.21 0 .42-.05.63-.05a5.5 5.5 0 0 1 4.58 2.36 2.52 2.52 0 0 0 2 1.06 2.4 2.4 0 0 0 2-1.06 5.62 5.62 0 0 1 10.21 2.79 6 6 0 0 1-2.27 4.9Z" />
  </svg>
)
export default SvgUspIconsHeart
