import * as React from 'react'

import type { SVGProps } from 'react'

const SvgUspIconsAnalysis = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24.93 27.96"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M15.1-.04a9.79 9.79 0 0 0-9.79 9.82 9.92 9.92 0 0 0 2.34 6.33L0 25.62l2.93 2.34 7.66-9.52a9.46 9.46 0 0 0 4.57 1.12 9.8 9.8 0 1 0-.06-19.6Zm0 15.89a6.06 6.06 0 1 1 6.06-6.06 6.06 6.06 0 0 1-6.06 6.1Z" />
  </svg>
)
export default SvgUspIconsAnalysis
