import * as React from 'react'

import type { SVGProps } from 'react'

const SvgArrowCircleLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 70 70"
    width="1em"
    height="1em"
    {...props}
  >
    <circle
      className="cls-1"
      cx="35"
      cy="35"
      r="34"
      fill="#000"
      strokeWidth="0"
    />
    <polygon
      className="cls-2"
      points="45.7 50.3 30.4 35 45.7 19.7 42.7 16.6 24.3 35 42.7 53.4 45.7 50.3"
      fill="#fff"
      strokeWidth="0"
    />
  </svg>
)
export default SvgArrowCircleLeft
