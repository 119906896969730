import * as React from 'react'

import type { SVGProps } from 'react'

const SvgHomepageClaimThree = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 31 28"
    {...props}
  >
    <path d="M14.4 15.4c.3-.2.6-.4.8-.7.9-.9 1.4-2 1.4-3.3s-.5-2.5-1.4-3.3c-.9-.9-2-1.4-3.3-1.4s-2.5.5-3.3 1.4c-.9.9-1.4 2-1.4 3.3s.5 2.5 1.4 3.3c.2.2.5.5.8.7-.9.3-1.6.9-2.3 1.5-1.2 1.2-2 2.9-2 4.7V28h13.4v-6.4c0-1.9-.8-3.5-2-4.7-.4-.6-1.2-1.1-2.1-1.5zm-4.9-1.5c-.6-.6-1-1.5-1-2.5s.4-1.8 1-2.5c.6-.6 1.5-1 2.5-1s1.8.4 2.5 1c.6.6 1 1.5 1 2.5s-.4 1.8-1 2.5c-.6.6-1.5 1-2.5 1s-1.9-.3-2.5-1zm8 13H6.4v-5.2c0-1.5.6-2.9 1.6-3.9s2.4-1.6 3.9-1.6 2.9.6 3.9 1.6 1.6 2.4 1.6 3.9v5.2h.1zM22.3 0c-.8 0-1.5.3-2 .8-.6-.5-1.3-.8-2-.8-2.1-.1-3.6 1.8-3.3 3.9.1.9.6 1.5 1.7 2.3l3.7 2.5 3.7-2.5c1.1-.8 1.5-1.4 1.7-2.3.2-2-1.4-3.9-3.5-3.9zm1.8 4.6-.5.5-3.3 2.2L17 5.1c-.2-.1-.4-.3-.5-.5-.3-.4-.4-.8-.4-1.1 0-1.2.9-2.3 2.1-2.3.6 0 1.1.2 1.5.6.2.1.3.3.4.5s.5.2.6 0c.1-.2.2-.4.4-.5.4-.4.9-.6 1.5-.6 1.2 0 2.1 1.1 2.1 2.3-.1.4-.2.7-.6 1.1z" />
  </svg>
)
export default SvgHomepageClaimThree
