import * as React from 'react'

import type { SVGProps } from 'react'

const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    {...props}
  >
    <path d="M22.148 15.148h-7v7h-2.297v-7h-7v-2.297h7v-7h2.297v7h7v2.297z" />
  </svg>
)
export default SvgAdd
