import * as React from 'react'

import type { SVGProps } from 'react'

const SvgArrowSimpleLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 100 100"
    {...props}
  >
    <path d="m69.9 50.2l-39.8 44.9c-.7.8-1.5.9-2.2.2-.7-.7-.8-1.5-.2-2.2l38.9-44-38.7-44c-.5-.7-.5-1.5 0-2.2.5-.2.8-.4 1.1-.4.5.1.8.3 1.1.5l39.8 45c.5.8.5 1.5 0 2.2" />
  </svg>
)
export default SvgArrowSimpleLeft
