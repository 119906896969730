import * as React from 'react'

import type { SVGProps } from 'react'

const SvgGbUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 27.95 44"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M28 44H0V32.09a14 14 0 0 1 28 0ZM1.21 42.79h25.54v-10.7a12.77 12.77 0 0 0-25.54 0Z" />
    <path d="M14 19.33a9.67 9.67 0 1 1 9.66-9.67A9.68 9.68 0 0 1 14 19.33Zm0-18.12a8.46 8.46 0 1 0 8.45 8.45A8.46 8.46 0 0 0 14 1.21Z" />
  </svg>
)
export default SvgGbUser
