import * as React from 'react'

import type { SVGProps } from 'react'

const SvgTelegram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M479.7 101.7 411.8 422c-5.1 22.6-18.5 28.2-37.5 17.6l-103.5-76.2-49.9 48c-5.5 5.5-10.1 10.1-20.8 10.1l7.4-105.4 191.8-173.3c8.3-7.4-1.8-11.6-13-4.1l-237 149.2-102-31.9c-22.2-6.9-22.6-22.2 4.6-32.8L451.1 69.3c18.5-6.9 34.6 4.1 28.6 32.4z" />
  </svg>
)
export default SvgTelegram
