import * as React from 'react'

import type { SVGProps } from 'react'

const SvgPackage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    {...props}
    viewBox="0 0 57.8 57.2"
  >
    <path
      d="M29.39,5.39.5,19.44v29.1L29.39,62.59l28.9-14.05V19.44ZM7.65,27.23l18.17,8.29V52.9L7.65,44.06ZM51.14,44.06,33,52.9v-22L12.46,21.57l16.93-8.23L51.14,23.91Z"
      transform="translate(-0.5 -5.39)"
    ></path>
    <polygon points="37.5 23.21 37.5 34.28 44.6 29.32 44.6 18.65 34.16 13.89 26.38 18.26 37.5 23.21"></polygon>
  </svg>
)
export default SvgPackage
