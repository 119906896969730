import * as React from 'react'

import type { SVGProps } from 'react'

const SvgUspIconsProduct = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27.7 28"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M20.6 4.85a7.12 7.12 0 0 0-13.5 0H2.95L0 28.03h27.7L24.75 4.85ZM13.85 3.5a3.46 3.46 0 0 1 2.8 1.35h-5.6a3.53 3.53 0 0 1 2.8-1.35ZM4 24.5 6.05 8.35H21.7l2.05 16.15Z" />
  </svg>
)
export default SvgUspIconsProduct
