import * as React from 'react'

import type { SVGProps } from 'react'

const SvgArrowGbLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 86 28"
    {...props}
  >
    <path d="m14.3 28 1.5-1.5L4.3 15.1h81V13H4.2L15.7 1.6 14.2.1.3 14z" />
  </svg>
)
export default SvgArrowGbLeft
