import * as React from 'react'

import type { SVGProps } from 'react'

const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 28"
    {...props}
  >
    <path d="M23.6 7.2 12 .1-.1 7.6v20.5h9.4v-8.2h5.5v8.2h9.3V7.5l-.5-.3zM22 25.9h-5v-8.2H7.1v8.2h-5V8.7L12 2.6l9.9 6.1.1 17.2z" />
  </svg>
)
export default SvgHome
