import * as React from 'react'

import type { SVGProps } from 'react'

const SvgSandWatch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 21.54 28"
    {...props}
  >
    <path
      d="M3.93,6.2a10.69,10.69,0,0,0,3.41,7.85A10.91,10.91,0,0,0,3.93,21.9V28H25.47V21.85A10.7,10.7,0,0,0,22.06,14a10.89,10.89,0,0,0,3.41-7.85V0H3.93ZM7.54,21.85a7.14,7.14,0,0,1,3.67-6.25L14.05,14l-2.84-1.6A7.14,7.14,0,0,1,7.54,6.15V3.62H21.85V6.15a7.12,7.12,0,0,1-3.67,6.25L15.34,14l2.79,1.6a7.12,7.12,0,0,1,3.67,6.25v2.53H7.49V21.85Z"
      transform="translate(-3.93)"
    ></path>
  </svg>
)
export default SvgSandWatch
