import * as React from 'react'

import type { SVGProps } from 'react'

const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    {...props}
  >
    <path d="M28 28V0H0v28h28zM2.2 2.1h23.7v23.7H2.2V2.1z" />
    <path d="M15.2 5.2H13V18h-1.6l2.6 4.7 2.8-4.7h-1.6z" />
  </svg>
)
export default SvgArrowDown
