import * as React from 'react'

import type { SVGProps } from 'react'

const SvgShoppingCart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 28"
    {...props}
  >
    <path d="M30 12c1.109 0 2 .891 2 2s-.891 2-2 2h-.234l-1.797 10.344c-.172.953-1 1.656-1.969 1.656H6a2.009 2.009 0 0 1-1.969-1.656L2.234 16H2c-1.109 0-2-.891-2-2s.891-2 2-2h28zM7.578 24.5a1.01 1.01 0 0 0 .922-1.078l-.5-6.5c-.047-.547-.531-.969-1.078-.922s-.969.531-.922 1.078l.5 6.5c.047.516.484.922 1 .922h.078zm6.422-1V17c0-.547-.453-1-1-1s-1 .453-1 1v6.5c0 .547.453 1 1 1s1-.453 1-1zm6 0V17c0-.547-.453-1-1-1s-1 .453-1 1v6.5c0 .547.453 1 1 1s1-.453 1-1zm5.5.078.5-6.5c.047-.547-.375-1.031-.922-1.078s-1.031.375-1.078.922l-.5 6.5a1.01 1.01 0 0 0 .922 1.078h.078c.516 0 .953-.406 1-.922zM7.438 4.562 5.985 11H3.922L5.5 4.109A3.966 3.966 0 0 1 9.391 1H12c0-.547.453-1 1-1h6c.547 0 1 .453 1 1h2.609A3.966 3.966 0 0 1 26.5 4.109L28.078 11h-2.063l-1.453-6.438a2.004 2.004 0 0 0-1.953-1.563H20c0 .547-.453 1-1 1h-6c-.547 0-1-.453-1-1H9.391c-.938 0-1.734.641-1.953 1.563z" />
  </svg>
)
export default SvgShoppingCart
