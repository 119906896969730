import * as React from 'react'

import type { SVGProps } from 'react'

const SvgArrowSimpleTop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    {...props}
  >
    <path d="M8.641 17.992 7 16.351l7-7 7 7-1.641 1.641L14 12.633z" />
  </svg>
)
export default SvgArrowSimpleTop
