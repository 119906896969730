import * as React from 'react'

import type { SVGProps } from 'react'

const SvgArrowCircleRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 70 70"
    width="1em"
    height="1em"
    {...props}
  >
    <circle
      className="cls-1"
      cx="36"
      cy="35"
      r="34"
      fill="#000"
      strokeWidth="0"
    />
    <polygon
      className="cls-2"
      points="25.3 19.7 40.6 35 25.3 50.3 28.3 53.4 46.7 35 28.3 16.6 25.3 19.7"
      fill="#fff"
      strokeWidth="0"
    />
  </svg>
)
export default SvgArrowCircleRight
