import * as React from 'react'

import type { SVGProps } from 'react'

const SvgCmnLab2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 75 53"
    {...props}
  >
    <path fill="#fcfcfc" d="M.66-.226v1.6c1.309-.436 1.165-.291 1.6-1.6H.66z" />
    <path fill="#adc1d1" d="m2.26-.226.8.8-.8-.8zM73.46-.226l.8.8-.8-.8z" />
    <path
      fill="#fcfcfc"
      d="M74.26-.226c.437 1.309.293 1.164 1.6 1.6v-1.6h-1.6z"
    />
    <path fill="#668eac" d="m1.46.574.8.8-.8-.8m72.8 0 .8.8-.8-.8z" />
    <path fill="#adc1d1" d="m.66 1.374.8.8-.8-.8m74.4 0 .8.8-.8-.8z" />
    <path fill="#1a5282" d="M-.804-.226h76.665V54H-.804z" />
    <path
      fill="#fcfcfc"
      d="M12.207 3.457c-1.138.028-1.326.004-1.354 1.674-.032 1.95.805 1.156 2.531 3.928-.132 1.391.094 7.213-.582 8.617-.575 1.729-2.8 6.745-5.023 12.256-.774 1.92-3.36 7.806-4.429 10.755-.81 2.233-1.176 4.79-.08 6.18.95 1.128 1.703 1.687 3.282 1.86 2.006.371 13.264.37 18.04.342 5.38-.032 8.214-.1 9.527-2.744 1.245-2.916.579-4.484-1.178-8.432-1.313-2.948-4.466-10.246-5.32-12.362-1.153-2.862-2.795-7.369-2.93-8.248-.17-4.58-.294-7.113-.225-8.49 1.474-2.346 2.976-1.895 2.972-4.356 0-.777.086-1.147-1.338-1.135l-13.893.155z"
    />
    <path fill="#396a93" d="m13.46 5.374.8.8-.8-.8z" />
    <path fill="#bdcfdb" d="m13.46 6.174.8.8-.8-.8z" />
    <path fill="#4e7da3" d="m11.86 6.974.8.8-.8-.8z" />
    <path fill="#8aa6bc" d="m14.26 6.974.8.8-.8-.8z" />
    <path
      fill="#1a5282"
      d="m10.957 26.58 14.836-.404c-.454-1.252-2.782-7.86-2.753-8.672-.223-4.4-.181-4.586-.223-8.959.06-1.112 2.655-2.472 2.894-3.452.154-.629-1.778-.422-7.403-.367-4.109.02-6.005-.263-5.84.53-.01.692 2.759 2.716 2.508 3.637.047 1.366.015 7.981-.287 8.679-.985 2.248-1.88 4.745-3.732 9.007z"
    />
    <path fill="#adc1d1" d="m12.66 7.774.8.8-.8-.8z" />
    <path fill="#4e7da3" d="m11.86 18.974.8.8-.8-.8z" />
    <path fill="#396a93" d="m14.26 18.974.8.8-.8-.8z" />
    <path fill="#adc1d1" d="m11.86 19.774.8.8-.8-.8m11.2 0 .8.8-.8-.8z" />
    <path fill="#668eac" d="m13.46 20.574.8.8-.8-.8z" />
    <path
      fill="#8aa6bc"
      d="m11.06 21.374.8.8-.8-.8zM12.66 22.174l.8.8-.8-.8m11.2 0 .8.8-.8-.8z"
    />
    <path fill="#668eac" d="m10.26 22.974.8.8-.8-.8z" />
    <path fill="#396a93" d="m12.66 22.974.8.8-.8-.8z" />
    <path fill="#bdcfdb" d="m10.26 23.774.8.8-.8-.8z" />
    <path fill="#668eac" d="m8.66 26.974.8.8-.8-.8z" />
    <path
      fill="#90e7ff"
      d="m10.505 27.956-1.118 2.518c1.075.027 2.301.056 3.298.058.376.001-.23.514-.405 1.088l-3.165-.174-1.264 2.967 3.085.076-.675 1.285-3.063-.097-1.29 2.83 3.233.015-.48 1.252-3.125-.051-.543 1.381 3.19.084-.5 1.267-3.178-.114s-.488 1.342-.083 2.669c1.404 2.448 2.306 2.011 3.896 2.044 4.474.093 11.51.145 14.837.139 7.088-.16 8.064.015 9.478-2.384.833-1.983-.284-3.149-2.364-8.235-1.02-2.483-2.941-6.83-3.824-8.488-2.402-.001-10.056-.245-15.94-.13z"
    />
    <path fill="#396a93" d="m7.86 28.574.8.8-.8-.8z" />
    <path fill="#adc1d1" d="m7.86 29.374.8.8-.8-.8z" />
    <path fill="#668eac" d="m7.06 30.974.8.8-.8-.8z" />
    <path fill="#4e7da3" d="m6.26 32.574.8.8-.8-.8z" />
    <path fill="#adc1d1" d="m6.26 33.374.8.8-.8-.8z" />
    <path fill="#8aa6bc" d="m5.46 34.974.8.8-.8-.8z" />
    <path fill="#4e7da3" d="m4.66 36.574.8.8-.8-.8z" />
    <path fill="#bdcfdb" d="m4.66 37.374.8.8-.8-.8z" />
    <path fill="#396a93" d="m3.86 38.174.8.8-.8-.8z" />
    <path fill="#668eac" d="m3.06 40.574.8.8-.8-.8z" />
    <path fill="#8aa6bc" d="m33.46 40.574.8.8-.8-.8z" />
    <path fill="#d1dbe2" d="m3.06 41.374.8.8-.8-.8z" />
    <path fill="#396a93" d="m2.26 42.174.8.8-.8-.8z" />
    <path
      fill="#fcfcfc"
      d="m28.38 42.252.53 1.585 1.585-.53-.624-1.725-1.49.67z"
    />
    <path fill="#668eac" d="m34.26 42.174.8.8-.8-.8z" />
    <path fill="#4e7da3" d="m3.06 46.974.8.8-.8-.8z" />
    <path fill="#668eac" d="m33.46 46.974.8.8-.8-.8z" />
    <path fill="#bdcfdb" d="m4.66 47.774.8.8-.8-.8z" />
    <path fill="#4e7da3" d="m32.66 47.774.8.8-.8-.8z" />
    <path fill="#adc1d1" d="m.66 50.174.8.8-.8-.8m74.4 0 .8.8-.8-.8z" />
    <path fill="#668eac" d="m1.46 50.974.8.8-.8-.8m72.8 0 .8.8-.8-.8z" />
    <path fill="#adc1d1" d="m2.26 51.774.8.8-.8-.8m71.2 0 .8.8-.8-.8z" />
    <g fill="#fff" strokeWidth={0.75} fontFamily="sans-serif" fontSize={30}>
      <path d="M26.906 20.389h3.592v-.992H28.09V14.46h-1.184v5.928z" />
      <path d="M32.396 17.3a42.64 42.64 0 0 0 .504-1.935h.04c.168.64.328 1.32.512 1.936l.176.648H32.22l.176-.648zm-2.048 3.089h1.2l.416-1.52h1.92l.416 1.52h1.24l-1.904-5.928h-1.392l-1.896 5.928zM35.853 20.389h2.112c1.304 0 2.296-.552 2.296-1.744 0-.8-.472-1.248-1.112-1.4v-.04c.504-.176.808-.736.808-1.28 0-1.104-.928-1.464-2.144-1.464h-1.96v5.928zm1.184-3.512v-1.504h.704c.712 0 1.064.208 1.064.728 0 .472-.328.776-1.08.776h-.688zm0 2.6v-1.728h.824c.816 0 1.248.248 1.248.824 0 .616-.44.904-1.248.904h-.824zM43.27 20.5c1.569 0 2.649-1.175 2.649-3.095 0-1.92-1.08-3.048-2.648-3.048s-2.648 1.12-2.648 3.048c0 1.92 1.08 3.096 2.648 3.096zm0-1.023c-.88 0-1.44-.808-1.44-2.072 0-1.272.56-2.032 1.44-2.032.88 0 1.44.76 1.44 2.032 0 1.264-.56 2.072-1.44 2.072zM46.696 20.389h1.184v-2.24h.824l1.216 2.24h1.32l-1.384-2.424c.68-.264 1.128-.816 1.128-1.704 0-1.36-.984-1.8-2.224-1.8h-2.064v5.928zm1.184-3.176v-1.808h.768c.76 0 1.184.216 1.184.856 0 .632-.424.952-1.184.952h-.768z" />
      <path d="M52.994 17.3a42.64 42.64 0 0 0 .504-1.935h.04c.168.64.328 1.32.512 1.936l.176.648h-1.408l.176-.648zm-2.048 3.089h1.2l.416-1.52h1.92l.416 1.52h1.24l-1.904-5.928h-1.392l-1.896 5.928zM57.011 20.389h1.184v-4.936h1.68v-.992h-4.528v.992h1.664v4.936zM62.557 20.5c1.568 0 2.648-1.175 2.648-3.095 0-1.92-1.08-3.048-2.648-3.048s-2.648 1.12-2.648 3.048c0 1.92 1.08 3.096 2.648 3.096zm0-1.023c-.88 0-1.44-.808-1.44-2.072 0-1.272.56-2.032 1.44-2.032.88 0 1.44.76 1.44 2.032 0 1.264-.56 2.072-1.44 2.072zM65.982 20.389h1.184v-2.24h.824l1.216 2.24h1.32l-1.384-2.424c.68-.264 1.128-.816 1.128-1.704 0-1.36-.984-1.8-2.224-1.8h-2.064v5.928zm1.184-3.176v-1.808h.768c.76 0 1.184.216 1.184.856 0 .632-.424.952-1.184.952h-.768zM71.96 20.389h1.184V18.22l1.784-3.76h-1.232l-.592 1.448c-.168.44-.344.856-.528 1.312h-.032a72.74 72.74 0 0 1-.512-1.312l-.592-1.448h-1.256l1.776 3.76v2.168z" />
    </g>
    <g fill="#90e7ff" strokeWidth={0.75} fontFamily="sans-serif" fontSize={30}>
      <path d="M32.912 32.324h1.776V24.92h2.52v-1.488h-6.792v1.488h2.496v7.404zM38.266 32.324h5.58v-1.488h-3.804V28.46h3.12v-1.488h-3.12V24.92h3.672v-1.488h-5.448v8.892zM47.915 32.492c2.052 0 3.264-1.236 3.264-2.688 0-1.284-.708-1.968-1.788-2.412l-1.164-.492c-.756-.3-1.38-.528-1.38-1.164 0-.588.492-.936 1.272-.936.732 0 1.32.264 1.884.732l.9-1.116c-.72-.732-1.752-1.14-2.784-1.14-1.8 0-3.072 1.116-3.072 2.568 0 1.296.9 2.016 1.8 2.388l1.188.504c.792.336 1.332.54 1.332 1.2 0 .624-.492 1.02-1.416 1.02-.78 0-1.62-.396-2.256-.984l-1.02 1.224a4.732 4.732 0 0 0 3.24 1.296zM53.857 32.324h1.776V24.92h2.52v-1.488H51.36v1.488h2.496v7.404zM59.21 32.324h5.58v-1.488h-3.804V28.46h3.12v-1.488h-3.12V24.92h3.672v-1.488H59.21v8.892zM66.208 32.324h2.532c2.616 0 4.296-1.488 4.296-4.476 0-3-1.68-4.416-4.392-4.416h-2.436v8.892zm1.776-1.428V24.86h.552c1.656 0 2.688.816 2.688 2.988 0 2.16-1.032 3.048-2.688 3.048h-.552z" />
    </g>
    <path
      fill="#fcfcfc"
      d="m25.254 32.678 2.773 8.347 1.515-.533-3.016-8.282-1.272.468z"
    />
  </svg>
)
export default SvgCmnLab2
