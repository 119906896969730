import * as React from 'react'

import type { SVGProps } from 'react'

const SvgUspIconsTime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21.54 28"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M0 6.2a10.69 10.69 0 0 0 3.41 7.85A10.91 10.91 0 0 0 0 21.9V28h21.54v-6.15A10.7 10.7 0 0 0 18.13 14a10.89 10.89 0 0 0 3.41-7.85V0H0Zm3.61 15.65a7.14 7.14 0 0 1 3.67-6.25l2.84-1.6-2.84-1.6a7.14 7.14 0 0 1-3.67-6.25V3.62h14.31v2.53a7.12 7.12 0 0 1-3.67 6.25L11.41 14l2.79 1.6a7.12 7.12 0 0 1 3.67 6.25v2.53H3.56v-2.53Z" />
  </svg>
)
export default SvgUspIconsTime
