import * as React from 'react'

import type { SVGProps } from 'react'

const SvgTick = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.98 31.07"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M12.49 31.07 0 18.38l3.56-3.51 8.56 8.69L31.08 0l3.9 3.13-22.49 27.94z" />
  </svg>
)
export default SvgTick
