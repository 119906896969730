import * as React from 'react'

import type { SVGProps } from 'react'

const SvgUspIconsBox = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57.8 57.2"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M28.89 0 0 14.05v29.1L28.89 57.2l28.9-14.05v-29.1ZM7.15 21.84l18.17 8.29v17.38L7.15 38.67Zm43.49 16.83L32.5 47.51v-22l-20.54-9.33 16.93-8.23 21.75 10.57Z" />
    <path d="M37.5 23.21v11.07l7.1-4.96V18.65l-10.44-4.76-7.78 4.37 11.12 4.95z" />
  </svg>
)
export default SvgUspIconsBox
