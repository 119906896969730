import * as React from 'react'

import type { SVGProps } from 'react'

const SvgCmnIso2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 94 66"
    {...props}
  >
    <path fill="#174e7e" d="M0 33V0h94v66H0Z" />
    <g fill="#fff">
      <path d="M16.536 59.536c.22-1.135.595-3.426.712-3.855.682.116 1.578.319 3.052.319 3.1 0 5.357-1.477 5.777-3.918-.871.213-2.303.434-4.107.349-1.939-.166-3.57-1.131-4.37-2.031-3.7-3.7-.3-10.4 5.3-10.4 4.7 0 7.3 2.9 7.8 8.6.5 4.3-1.225 6.991-2.2 8.2-1.284 1.617-3.4 3.226-7.1 3.2-3.17-.022-3.903-.178-4.864-.464zm8.765-11.756C26.533 45.749 24.9 44 22.9 44c-1.9 0-3.175 2.9-1.675 4.3.9 1 3.255 1.044 4.076-.52zM36 58c-2-2-2.3-3.6-2.1-10 .1-6.5 7.2-10.1 12.1-6.2 3.4 2.8 4 11.7 1 15.5-2.7 3.4-8 3.7-11 .7zm7.4-3.6c1.077-4.194.406-10.463-2.185-10.143-3.243-.206-3.217 6.099-2.112 10.027.924 2.415 3.734 2.31 4.297.116zM72 53.5c.042-3.795.102-6.145-.04-6.316-.152-.183-.896.305-1.96.816-.744.394-1.33.627-2.02.921.008-.547-.08-4.053-.042-4.727 1.78-.966 1.64-.85 4.68-2.328 1.707-.763 3.411-1.721 4.376-1.991C77.038 40.594 77 43.2 77 50c0 10-.016 7-.078 10-1.334.034-4.248.01-4.919-.013-.032-1.178-.043-2.862-.003-6.487zM31.992 34.493c-.789-.193-1.733-.35-2.806-.788.051-.594.47-1.922.587-2.785.167-1.233.308-1.835.388-2.629.936.192 2.97 1.042 5.998 1.042 4.8 0 5.606-.956 5.606-2.456 0-1.3-1.7-2.5-5.5-4-4-1.6-5.7-2.9-6.4-4.8-2-5.9 1.7-10.6 9.3-11.6 2.769-.447 7.178.278 9.616.765-.138.922-1.032 3.954-1.548 5.84-1.198-.367-2.168-.584-3.768-.705-3.2-.3-5.2.1-6 1-1.5 1.9-.4 3.1 4.7 4.8 9 3 10.7 11.4 3.1 15.2-2.077 1.07-5.161 1.821-8.125 1.818a20.644 20.644 0 0 1-5.148-.702z" />
      <path d="M58.3 33.4c-5.8-2.9-8.2-13.5-4.7-20.9 3.8-8.1 17.8-8.6 22.9-.9 2.6 3.9 2.7 13.9.2 17.8-3.4 5.2-12.1 7.1-18.4 4zm10.9-6.1c2.6-2.3 2.5-11.3-.1-13.7-2.7-2.4-7.2-1.7-8.9 1.4-1.5 2.9-1 10.3.8 12.5 1.7 2 5.9 1.9 8.2-.2zM19 20.5c0-8.639 0-12.343.003-13.479C19.691 7.023 21.24 7 22.5 7c1.19 0 2.886.008 3.493.007C25.973 8.5 26 11.587 26 20.5c0 13.5-.058 12.593-.058 14.914 0 0-2.879.067-3.629.058-.919-.01-2.886.045-3.253.042C19.035 33.743 19 21.423 19 20.5z" />
    </g>
    <path
      fill="#fff"
      d="M53.43 58.141c-2-2-2.3-3.6-2.1-10 .1-6.5 7.2-10.1 12.1-6.2 3.4 2.8 4 11.7 1 15.5-2.7 3.4-8 3.7-11 .7zm7.4-3.6c1.077-4.193.406-10.462-2.184-10.143-3.244-.206-3.218 6.099-2.112 10.027.923 2.415 3.733 2.31 4.296.116z"
    />
  </svg>
)
export default SvgCmnIso2
