import { forwardRef, HTMLAttributes, ReactNode } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

import { UISize } from '@/common/types/ui-types'
import { Loader, LoaderProps } from '../loader'

const variants = cva(
  [
    'text-white',
    'font-bold',
    'leading-normal',
    'items-center',
    'justify-center',
    'transition-colors duration-500 ease-in-out',
  ],
  {
    variants: {
      variant: {
        none: [],
        primary: ['bg-primary'],
        secondary: ['bg-secondary', 'hover:bg-secondary-hover'],
        tertiary: [
          'bg-shadow',
          'hover:bg-grey-400',
          'transition ease-in-out duration-500',
        ],
        success: ['bg-success', 'hover:bg-success-strong'],
        ghost: ['bg-none', 'text-grey-500', 'hover:bg-grey-50'],
        transparent: ['bg-none', 'hover:bg-transparent'],
        boxed: [
          'bg-transparent',
          'hover:bg-grey-50',
          'border',
          'border-black',
          'border-2',
          'hover:border-secondary',
          'hover:text-secondary',
          'hover:fill-secondary',
        ],
        shadow: [
          'bg-shadow',
          'hover:bg-secondary',
          'transition ease-in-out duration-500',
        ],
      },
      size: {
        xs: ['w-6', 'h-6', 'font-semibold'],
        sm: ['w-8', 'h-8', 'font-semibold'],
        base: ['w-12', 'h-12'],
        lg: ['w-16', 'h-16', 'text-2xl'],
        xl: ['w-20', 'h-20'],
      },
      disabled: {
        true: ['opacity-50', 'cursor-not-allowed', 'hover:disabled:bg'],
      },
    },
    compoundVariants: [],

    defaultVariants: {
      variant: 'primary',
      size: 'base',
    },
  },
)

export type IconButtonProps = {
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'ghost'
    | 'transparent'
    | 'boxed'
    | 'shadow'
    | 'tertiary'
    | 'none'

  size?: UISize
  loader?: boolean | ReactNode
  disabled?: boolean
  loaderProps?: LoaderProps
} & HTMLAttributes<HTMLButtonElement>

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const { children, loader, className, loaderProps, ...rest } = props

    return (
      <button
        ref={ref}
        className={twMerge(variants({ ...rest, className }))}
        tabIndex={0}
        {...rest}
      >
        <Loader loader={loader} {...loaderProps}>
          {children}
        </Loader>
      </button>
    )
  },
)

IconButton.displayName = 'IconButton'
export { IconButton }
