import * as React from 'react'

import type { SVGProps } from 'react'

const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 31.74 28"
    {...props}
  >
    <path
      d="M24.42.06a9.46,9.46,0,0,0-7.58,2.68A9.27,9.27,0,0,0,9.27.06,9.41,9.41,0,0,0,1,8.53a9.25,9.25,0,0,0,3.48,8L15.05,27.26a2.39,2.39,0,0,0,1.74.74h0a2.43,2.43,0,0,0,1.68-.68L29.16,16.63a9.39,9.39,0,0,0,3.52-8A9.45,9.45,0,0,0,24.42.06Zm2.42,13.68-10,10L7,13.9l-.16-.16a5.6,5.6,0,0,1,2.85-10c.21,0,.42-.05.63-.05A5.5,5.5,0,0,1,14.9,6.05a2.52,2.52,0,0,0,2,1.06,2.4,2.4,0,0,0,2-1.06A5.62,5.62,0,0,1,29.11,8.84,6,6,0,0,1,26.84,13.74Z"
      transform="translate(-0.97)"
    ></path>
  </svg>
)
export default SvgHeart
