import * as React from 'react'

import type { SVGProps } from 'react'

const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    {...props}
  >
    <path d="M14 9.2H1.5L6 28h16l4.5-18.8H14zm-1 16.7h-1.8L9.9 11.4h3v14.5h.1zm2.1-14.5h3l-1.3 14.5h-1.7V11.4zm-10.8 0h3.5l1.3 14.5H7.9L4.3 11.4zm16 14.5h-1.2l1.3-14.5h3.5l-3.6 14.5zM20.9 5l-1.8-5H9L7.2 5H1.6v2.2h25V5h-5.7zM9.5 5l1-2.8h7.2l1 2.8H9.5z" />
  </svg>
)
export default SvgTrash
