import * as React from 'react'

import type { SVGProps } from 'react'

const SvgArrowGbRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 86 28"
    {...props}
  >
    <path d="m71.4.1-1.5 1.5L81.3 13H.3v2.1h81.1L70 26.5l1.4 1.5 13.9-13.9z" />
  </svg>
)
export default SvgArrowGbRight
