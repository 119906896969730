import * as React from 'react'

import type { SVGProps } from 'react'

const SvgPen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    {...props}
  >
    <path d="m26.595 5.862-4.313-4.313 1.556-1.556 4.313 4.313-1.556 1.556zM26.419 9.164l1.556 1.556-8.98 8.98-1.556-1.556 8.98-8.98zM20.7 2.9 0 23.6v4.5h4.5L25.1 7.5l-4.4-4.6zm-17 23H2.2v-1.5L20.7 5.9l1.5 1.5L3.7 25.9z" />
  </svg>
)
export default SvgPen
