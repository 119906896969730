import * as React from 'react'

import type { SVGProps } from 'react'

const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    {...props}
  >
    <path d="m22.8 3.1 1.6 1.6c1.9 1.9 1.9 5.1 0 7L11.7 24.4c-.9.9-2.2 1.5-3.5 1.5s-2.6-.5-3.5-1.5l-1.6-1.6 4.6-4.6 2.5 2.5 10.4-10.4-2.5-2.5 4.7-4.7zm0-3.1-7.7 7.7 2.6 2.6-7.4 7.4-2.6-2.6L0 22.8l3.1 3.1C4.5 27.3 6.3 28 8.2 28c1.8 0 3.7-.7 5.1-2.1L26 13.2c2.8-2.8 2.8-7.3 0-10.1L22.8 0z" />
  </svg>
)
export default SvgPhone
