import * as React from 'react'

import type { SVGProps } from 'react'

const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    {...props}
  >
    <path d="M26.652 2.475c-.064.02-.155.056-.204.082-.048.028-3.943 2.66-8.657 5.853a2284.717 2284.717 0 0 1-8.596 5.808c-.017 0-1.682-1.26-3.702-2.805C3.471 9.869 1.767 8.58 1.705 8.55a.962.962 0 0 0-1.093.185c-.28.282-.368.755-.202 1.1.028.057 1.833 3.047 4.01 6.642 3.033 5.01 3.984 6.563 4.07 6.653a.86.86 0 0 0 .252.181c.395.185.795.114 1.118-.202.075-.073 1.799-1.915 3.825-4.092 2.028-2.177 5.995-6.432 8.813-9.454 2.82-3.022 5.154-5.54 5.189-5.593a.859.859 0 0 0 .157-.549c-.002-.26-.048-.397-.198-.598a.934.934 0 0 0-.714-.372.943.943 0 0 0-.28.024z" />
  </svg>
)
export default SvgCheck
