import * as React from 'react'

import type { SVGProps } from 'react'

const SvgTime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 25"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M12.932.671C6.126.671.68 6.116.68 12.923s5.446 12.253 12.252 12.253c6.807 0 12.253-5.446 12.253-12.253S19.625.671 12.932.671zm0 22.009c-5.332 0-9.756-4.425-9.756-9.757S7.6 3.167 12.932 3.167s9.757 4.424 9.757 9.756-4.425 9.757-9.757 9.757z" />
    <path d="M14.067 12.356v-6.24c0-.68-.567-1.247-1.248-1.247-.68 0-1.248.567-1.248 1.247v6.807c0 .114 0 .34.113.454.114.113.114.34.227.454l3.29 3.29c.454.454 1.248.454 1.815 0 .454-.454.454-1.248 0-1.815l-2.95-2.95z" />
  </svg>
)
export default SvgTime
