import * as React from 'react'

import type { SVGProps } from 'react'

const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    {...props}
  >
    <path d="M28 1.5 26.5 0 14 12.5 1.5 0 0 1.5 12.5 14 0 26.5 1.5 28 14 15.5 26.5 28l1.5-1.5L15.5 14z" />
  </svg>
)
export default SvgClose
