import * as React from 'react'

import type { SVGProps } from 'react'

const SvgHomepageClaimTwo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 27 28"
    {...props}
  >
    <path d="M21.1 13.4c.9-.9 1.5-2.2 1.5-3.6s-.6-2.7-1.5-3.6c-.9-.9-2.2-1.5-3.6-1.5s-2.7.6-3.6 1.5c-.9.9-1.5 2.2-1.5 3.6s.6 2.7 1.5 3.6c.9.9 2.2 1.5 3.6 1.5.8 0 1.6-.2 2.3-.6l4.3 5.7 1-.8-4.3-5.7c.1.1.2 0 .3-.1zm-3.6.3c-1 0-2-.4-2.7-1.1s-1.1-1.6-1.1-2.7.4-2 1.1-2.7c.7-.7 1.6-1.1 2.7-1.1s2 .4 2.7 1.1c.7.7 1.1 1.6 1.1 2.7 0 1-.4 2-1.1 2.7-.7.6-1.7 1.1-2.7 1.1z" />
    <path d="M3.7 5.3h1.2v14.4c0 .9.4 1.8 1 2.4s1.5 1 2.4 1c.9 0 1.8-.4 2.4-1s1-1.5 1-2.4V5.3h.9V4h-.9V0H4.8v4H2.3v24h16.9v-1.3H3.5V5.3zm2.4-4h4.3V4H6.1V1.3zm0 4h4.3v14.4c0 .6-.2 1.1-.6 1.5s-.9.6-1.5.6-1.1-.2-1.5-.6-.6-.9-.6-1.5L6.1 5.3z" />
  </svg>
)
export default SvgHomepageClaimTwo
