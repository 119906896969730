import * as React from 'react'

import type { SVGProps } from 'react'

const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    {...props}
  >
    <path d="M0 0v28h28V0H0zm25.8 25.9H2.1V2.2h23.7v23.7z" />
    <path d="M12.8 22.8H15V10h1.6L14 5.3 11.2 10h1.6z" />
  </svg>
)
export default SvgArrowUp
