import * as React from 'react'

import type { SVGProps } from 'react'

const SvgHomepageClaimOne = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 23 28"
    {...props}
  >
    <path d="m20.3 4.5.7-2.9-1.3.7C19 1.2 17.9.5 16.5.3c-.2 0-.5-.1-.7-.1h-.2c-.7 0-1.5.2-2.1.6-.6.3-1.2.8-1.6 1.4l1 .7c.3-.4.7-.8 1.2-1 .5-.3 1-.4 1.5-.4h.1c.3 0 .5 0 .7.1h.2c.2 0 .3.1.5.2.3.2.7.4.9.6l.1.1s.1.1.1.2.1.1.1.2l-1.2.6v-.3H3.4v6.2c0 1.9.8 3.6 2 4.8.6.6 1.3 1.1 2.2 1.5-.8.3-1.5.8-2.2 1.5-1.2 1.2-2 2.9-2 4.7v6.2h13.7v-6.2c0-1.8-.8-3.5-2-4.7-.6-.6-1.3-1.1-2.2-1.5.8-.3 1.5-.8 2.2-1.5 1.2-1.2 2-3 2-4.8v-.1c.3-.1.6-.3.9-.4.7-.4 1.2-1 1.6-1.8l-1.1-.6c-.3.5-.7 1-1.2 1.3-.1 0-.2.1-.2.1V3.5l3.2 1zm-4 22.4H5v-4.4c.1 0 .2.1.3.1.4.2.9.3 1.3.3s.9-.1 1.3-.3c.4-.2.8-.4 1.2-.8.2-.2.5-.4.8-.5s.6-.2.9-.2c.3 0 .6.1.9.2s.5.3.8.5c.3.3.7.6 1.2.8.4.2.9.3 1.3.3s.9-.1 1.3-.3c.1 0 .2-.1.3-.1l-.3 4.4zM14.7 18c.8.8 1.4 1.9 1.6 3.1-.2.2-.4.3-.7.4s-.6.2-.9.2-.6-.1-.9-.2c-.3-.1-.5-.3-.8-.5-.3-.3-.7-.6-1.2-.8-.4-.2-.9-.3-1.3-.3s-.9.1-1.3.3c-.4.2-.8.4-1.2.8-.2.2-.5.4-.8.5s-.6.2-.9.2c-.3 0-.6-.1-.9-.2-.2-.1-.5-.2-.7-.4.2-1.2.8-2.3 1.6-3.1 1-1 2.4-1.7 4-1.7 1.9 0 3.3.7 4.4 1.7zm-.9-10.3c-.1 0-.1-.1-.2-.1-.2-.2-.5-.4-.6-.7 0 0-.1-.1-.1-.2l1.3-.7-2.9-1-.7 2.9 1.2-.7c.1.2.2.3.3.5l.2.2s.1.1.1.2l.3.3c.8.7 1.9 1.1 3 1.1h.5c0 1.5-.7 2.8-1.6 3.8-1 1-2.4 1.7-4 1.7s-3-.6-4-1.7-1.7-2.4-1.7-4v-5h11.3v4h-.7c-.6 0-1.2-.2-1.7-.6z" />
  </svg>
)
export default SvgHomepageClaimOne
