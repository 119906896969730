import * as React from 'react'

import type { SVGProps } from 'react'

const SvgPiktogramHomepage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    {...props}
  >
    <path d="M22.5 2.8V0h-9.8v3.4h9.8v-.6zm-8.6-.6v-.9h7.3v.9h-7.3zM20 4.1h-4.8c-1.4 0-2.8.6-3.7 1.5S10.1 7.7 10 9H5.4v3.3h7.1V9.1h-1.4c.1-1 .5-1.9 1.2-2.5.7-.7 1.7-1.2 2.8-1.2h4.8c1.1 0 2.1.4 2.8 1.2.7.7 1.2 1.7 1.2 2.8v16.2h-9v-8.7c0-1.2-.5-2.2-1.2-3-.8-.8-1.8-1.2-3-1.2H7.2c-1.2 0-2.2.5-3 1.2-.8.8-1.2 1.8-1.2 3v11.2h11.9v-1.2h10.3V9.4c0-1.4-.6-2.8-1.5-3.7-1-1-2.3-1.6-3.7-1.6zm-8.7 6.2v.8H6.6v-.8h4.7zm2.4 16.5H4.3v-10c0-.8.3-1.5.9-2.1.5-.5 1.3-.9 2.1-.9h3.5c.8 0 1.5.3 2.1.9.5.5.9 1.3.9 2.1l-.1 10z" />
  </svg>
)
export default SvgPiktogramHomepage
