import * as React from 'react'

import type { SVGProps } from 'react'

const SvgFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 27 28"
    {...props}
  >
    <path d="m25.6 2-.7.7c-.8.8-1.7 1.4-2.7 1.8s-2.1.6-3.1.6-2.1-.2-3.1-.6c-1-.4-1.9-1-2.7-1.8-.9-.9-1.9-1.6-3-2S8 0 6.8 0 4.4.2 3.3.7s-2.1 1.1-3 2L0 3v25h2.1V16.2c.6-.5 1.3-1 2-1.3.8-.3 1.7-.5 2.7-.5.9 0 1.8.2 2.7.5s1.6.9 2.3 1.6l.1.1c1 .9 2.1 1.7 3.3 2.2 1.3.5 2.6.8 3.9.8s2.7-.3 3.9-.8c1.3-.5 2.4-1.3 3.5-2.3.2-.2.3-.5.3-.8V2.3c-.1-.4-.8-.7-1.2-.3zm-1 13.4c-.7.7-1.6 1.2-2.4 1.5-1 .4-2.1.6-3.1.6s-2.1-.2-3.1-.6c-1-.4-1.9-1-2.7-1.8l-.1-.1c-.9-.8-1.9-1.5-2.9-1.9-1.1-.5-2.3-.7-3.5-.7s-2.4.2-3.5.7l-1.2.6V4c.6-.5 1.3-1 2-1.3.8-.3 1.7-.5 2.7-.5.9 0 1.8.2 2.7.5s1.6.9 2.3 1.6c1 1 2.2 1.8 3.5 2.3s2.6.8 3.9.8 2.7-.3 3.9-.8c.6-.2 1.1-.5 1.6-.8l-.1 9.6z" />
  </svg>
)
export default SvgFlag
