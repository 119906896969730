import * as React from 'react'

import type { SVGProps } from 'react'

const SvgHamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 38 28"
    {...props}
  >
    <path d="M0 6.6h37.7V4.5H0zM0 15.1h37.7V13H0zM0 23.6h37.7v-2.1H0z" />
  </svg>
)
export default SvgHamburger
