import * as React from 'react'

import type { SVGProps } from 'react'

const SvgArrowThinDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="M18.211.062v23.404l9.1-8.852v6.258L15.937 31.938 4.563 20.872v-6.258l9.099 8.852V.062h4.548z" />
  </svg>
)
export default SvgArrowThinDown
