import * as React from 'react'

import type { SVGProps } from 'react'

const SvgAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M12 1 0 23h24L12 1zm1 18h-2v-2h2v2zm-2-3v-6h2v6h-2z" />
  </svg>
)
export default SvgAlert
