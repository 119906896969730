import * as React from 'react'

import type { SVGProps } from 'react'

const SvgBag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 27.7 28"
    {...props}
  >
    <path d="M21.35,4.82a7.12,7.12,0,0,0-13.5,0H3.7L.75,28h27.7L25.5,4.82ZM14.6,3.47a3.46,3.46,0,0,1,2.8,1.35H11.8A3.53,3.53,0,0,1,14.6,3.47Zm-9.85,21L6.8,8.32H22.45L24.5,24.47Z"></path>
  </svg>
)
export default SvgBag
