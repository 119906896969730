import * as React from 'react'
import { SelectHTMLAttributes } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

const variants = cva(
  [
    'h-field',
    'text-base',
    'leading-7',
    'px-3',
    'transition',
    'rounded-none',
    'focus:rounded-none',
    'focus:shadow-md',
    'focus:shadow-grey-200',
    'disabled:cursor-not-allowed',
    'disabled:opacity-50',
    'outline-none',
  ],
  {
    variants: {
      variant: {
        normal: ['border-2', 'border-black'],
        error: ['border-2', 'border-red-500'],
        autocomplete: [
          'border-2',
          'border-black',
          'focus:shadow-none',
          'focus:border-black',
        ],
      },
      disabled: {
        true: ['hover:border-auto', 'border-grey-300'],
      },
    },
    defaultVariants: {
      variant: 'normal',
    },
  },
)

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  variant?: 'normal' | 'error'
  required?: boolean
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({ variant, required, className, children, ...props }, ref) => {
    return (
      <select
        data-test="select"
        className={twMerge(
          variants({ variant, disabled: props.disabled, className }),
        )}
        ref={ref}
        required={required}
        aria-required={required}
        {...props}
      >
        {children}
      </select>
    )
  },
)

Select.displayName = 'Select'
export { Select }
